<template>
  <div>
    <div class="grant-matches-header">
      <div>
        <h1 class="matches-title">Grant Funding</h1>
        <p class="matches-subtitle">
          This tool shows you all available grants that may be right for you. We then send you directly from our grant finder through to the application page for the relevant grant
          provider. <router-link class="link bold" :to="{ name: 'integrations' }" v-if="integrationsText">{{ integrationsText }}</router-link>
        </p>
        <span class="asterisk">* It's important to note that Swoop Funding does not review or fund these grant applications</span>
      </div>
      <ContactBanner
        :icon="$dictionary.matchesContactBanner.icon"
        :actionText="$dictionary.matchesContactBanner.actionText"
        :name="$dictionary.matchesContactBanner.name"
        :phoneNumber="$dictionary.matchesContactBanner.phoneNumber"
        :email="$dictionary.matchesContactBanner.email"
      />
    </div>
    <FormOverlay v-if="isPageOverlayOpen">
      <EnquiryForm  @refetch-products="refetchProducts" />
    </FormOverlay>
    <div class="button-container">
      <button class="white-btn button" @click="openOverlayForm('findGrant')">View and edit questions <em class="material-icons">edit_note</em></button>
    </div>
    <!-- filterBar -->
    <div class="filter-bar-container">
      <div class="sort-container">
        <div class="sort-menu" @click="hideMenu()">
          <em class="icon material-icons">sort</em>
          <div>Sort by</div>
        </div>

        <button :class="[{ hidden: hideSortButtons, active: isButtonActive === 'amount' }, 'sort-btn btn-transparent']" tabindex="1" @click="sortByAmount()">
          <div class="sort-title">Amount</div>
          <div class="divider"></div>
          <em :class="[{ rotate: sortToggleAmount }, 'icon material-icons']">arrow_downward</em>
        </button>
      </div>
    </div>
    <div class="bold margin">{{ productCount }} results</div>
    <ProductList :products="slicedProducts" :locked="locked" :seeMore="productCount > 3" @see-more="toggleSeeMore">
      <template v-slot:product-list-empty>
        <ProductListEmpty :route="{ name: 'goals-form', params: { formRoute: 'grant' } }" productType="grant" />
      </template>
      <template v-slot:product-detail="{ product }">
        <ProductDetail :title="'Your Grant Application'" :product="product" />
      </template>
    </ProductList>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { toastTopCenterOptions } from '@/config/vue-toast'
import ProductList from '@product/components/products/ProductList'
import ProductListEmpty from '@product/components/products/ProductListEmpty'
import ProductDetail from '@product/components/products/ProductDetail'
import ContactBanner from '@/components/ContactBanner.vue'
import whitelabel from '@/config/whitelabel'
import amplitudeTracking from '@/mixins/amplitudeTracking'
import { amplitudeEvents } from '@/utils/constants'
import { companyGetters } from '@/store/modules/company/routines'
import { pageOverlayGetters, pageOverlayRoutine } from '@/store/modules/pageOverlay/routines'
import { currentFormNameRoutine } from '@/store/modules/currentFormDetails/routines'
import EnquiryForm from '../../goals/components/EnquiryForm'
import FormOverlay from '@/components/FormOverlay'

export default {
  components: {
    ProductDetail,
    ProductList,
    ProductListEmpty,
    ContactBanner,
    FormOverlay,
    EnquiryForm
  },
  mixins: [amplitudeTracking],
  props: {
    locked: {
      type: Boolean,
      default: false
    },
    products: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      desiredAmount: null,
      selectedStatus: 'all',
      sortToggleAmount: false,
      hideSortButtons: false,
      sortedProducts: this.products || [],
      isButtonActive: null,
      seeMore: false
    }
  },
  mounted() {
    if (this.companyId == null) {
      this.$toasted.show('Your application has been registered', {
        ...toastTopCenterOptions,
        type: 'success',
        icon: 'error_outline'
      })
      window.localStorage.removeItem('enquiryFormFinished')
    }
    this.trackLloydsGrantFinderEvent(amplitudeEvents.pageViews.LLOYDS_BANK_GRANT_FINDER, 'Matches')
  },
  beforeMount() {
    if (!this.$config.whitelabel.journey.grantsEnabled) {
      this.$router.push({ name: 'dashboard' })
    }
    this.setCurrentFormName('findGrant')
  },
  methods: {
    ...mapActions({
      setPageOverlay: pageOverlayRoutine.TRIGGER,
      setCurrentFormName: currentFormNameRoutine.TRIGGER
    }),
    refetchProducts(value) {
      this.setCurrentFormName('findGrant')
      this.$emit('refetch-products', value)
    },
    openOverlayForm() {
      this.$ma.trackEvent({ eventType: amplitudeEvents.Matches.VIEW_AND_EDIT_GRANT_FORM })
      this.setCurrentFormName('findGrant')
      this.setPageOverlay(!this.isPageOverlayOpen)
    },
    toggleSeeMore() {
      this.seeMore = !this.seeMore
    },
    hideMenu() {
      this.hideSortButtons = !this.hideSortButtons
    },
    sortByAmount() {
      this.isButtonActive = 'amount'
      this.sortToggleAmount = !this.sortToggleAmount
      this.$ma.trackEvent({ eventType: amplitudeEvents.Matches.SORT_BY_GRANT_AMOUNT })
      this.sortedProducts = [...this.sortedProducts].sort((a, b) => (this.sortToggleAmount ? b.maxSize - a.maxSize : a.maxSize - b.maxSize))
    }
  },
  computed: {
    ...mapGetters({
      companyId: companyGetters.COMPANY_ID,
      isPageOverlayOpen: pageOverlayGetters.IS_PAGE_OVERLAY_OPEN
    }),
    slicedProducts() {
      if (!this.seeMore) {
        return this.sortedProducts.slice(0, 3)
      }
      return this.sortedProducts
    },
    productCount() {
      return this.sortedProducts.length
    },
    integrationsText() {
      if (whitelabel.features.linkAccountancy && whitelabel.features.linkBankAccount) {
        return 'Integrate your bank or accountancy software here, to save time on your application.'
      }
      if (whitelabel.features.linkAccountancy) {
        return 'Integrate your accountancy software here, to save time on your application.'
      }
      if (whitelabel.features.linkBankAccount) {
        return 'Integrate your bank here, to save time on your application.'
      }
      return ''
    }
  },
  watch: {
    async products(value) {
      this.loading = true
      this.sortedProducts = value
    }
  }
}
</script>
<style scoped lang="scss">
@import '@/assets/styles/swoop/_mixins.scss';

.button-container {
  @include flex-display(column, flex-end, center);
  .button {
    height: 35px;
    white-space: nowrap;

    .material-icons {
      margin: 0 5px;
      font-size: 15px;
      vertical-align: middle;
    }
  }
}
</style>
